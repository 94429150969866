<template>
  <div class="readme-article">
    <div id="招生"></div>
    <h1 id="线索管理">
      四、其他
    </h1>
    <h2 id="线索名片">1.线索名片</h2>
    <p>各个平台自动进线的线索会首先进入到线索名片中，由管理员分配给员工，员工领取后才会显示在招生线索中</p>
    <p>
      <img src="@/assets/img/student/4-2.png">
    </p>
    <h2 id="验证电话号码">2.验证电话号码</h2>
    <p>可批量验证号码是否在系统有记录，有则显示相应信息，无则显示新号码</p>
    <p>
      <img src="@/assets/img/student/4-1.png">
    </p>
    <h2 id="统计报表">3.统计报表</h2>
    <p>查看支持的数据统计信息，包括招生线索统计，新增线索统计，线索来源统计等，统计信息详情下方支持导出报表</p>
    <p>
      <img src="@/assets/img/student/4-3.png">
    </p>
    <p>
      <img src="@/assets/img/student/4-4.png">
    </p>
    <h2 id="业绩查看">4.业绩查看</h2>
    <p>查看老师业绩，展示学员支付的流水信息，包含金额，付款方式，班主任等信息</p>
    <p>
      <img src="@/assets/img/student/4-5.png">
    </p>
    <h2 id="导入数据">5.导入数据</h2>
    <p>系统支持表格导入招生线索及客服名片，并提供导入文件模板，按模板格式上传即可一键导入数据</p>
    <p>
      <img src="@/assets/img/student/4-6.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Student4-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>